import { createTheme } from '@mui/material/styles';

// A custom theme for this app
// Ensure theme is properly structured for Material UI v5
const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#9c27b0',
    },
    background: {
      default: '#ffffff',
      paper: '#f0f0f0',
    },
    error: {
      main: '#d50000',
    },
    warning: {
      main: '#d81b60',
    },
    info: {
      main: '#0277bd',
    },
    success: {
      main: '#689f38',
    },
    text: {
      primary: 'rgba(0,0,0,0.87)',
      secondary: 'rgba(0,0,0,0.6)',
    }
  },
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      fontSize: '3.3rem',
      fontWeight: 700,
      fontFamily: 'Montserrat',
    },
    h2: {
      fontFamily: 'Montserrat',
      fontSize: '3rem',
      fontWeight: 700,
      marginBottom: '1rem',
    },
    h3: {
      fontSize: '1.5625rem',
      lineHeight: '1.4em',
      fontWeight: '700',
    },
    h4: {
      fontSize: '1.125rem',
      lineHeight: '1.5em',
      fontWeight: '700',
      margin: '2rem 0 1rem',
    },
    body1: {
      fontFamily: 'Open Sans',
      fontSize: '1.0625rem',
      lineHeight: '1.55em',
      fontWeight: '300',
      marginBottom: '0.6rem',
      '& b': {
        fontWeight: 700,
        // color: '#ea7500', // primary.main
      },
    },
    body2: {
      fontFamily: 'Open Sans',
    },
    button: {
      fontFamily: 'Open Sans',
    },
    caption: {
      fontFamily: 'Open Sans',
    },
    overline: {
      fontFamily: 'Open Sans',
    },
  },
  shape: {
    borderRadius: 0,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
        },
        body1: {
          textTransform: 'none',
        },
      },
    },
    MuiListItem: {
      variants: [
        {
          props: { variant: 'navItem' },
          style: {
            p: 0,
            color: '#222',
            '&:hover': { backgroundColor: 'rgba(0,0,0,0.26)' },
          },
        },
      ],
    },
    MuiGrid: {
      variants: [
        {
          props: { variant: 'content' },
          style: {
            textAlign: 'center',
            padding: '2.5rem',
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'aleign' },
          style: {
            fontWeight: 700,
            fontSize: '0.75rem',
            padding: '0.75rem 2.5rem',
            margin: '.3125rem 1px',
            lineHeight: '1.25rem',
            letterSpacing: '.2em',
            border: '0.125rem solid transparent',
            outline: '0.0625rem solid transparent',
            transition: 'all .5s ease .25s',
            minWidth: '10rem',
            '&:hover': {
              borderColor: 'white',
            },
            '&:after, &:before': {
              transition: 'all .2s ease 0s',
              content: "''",
              position: 'absolute',
              width: '0.125rem',
              height: '100%',
              top: 0,
              background: 'white',
            },
            '&:before': {
              left: '-0.5rem',
            },
            '&:hover:before': {
              left: -0.125,
            },
            '&:after': {
              right: '-0.5rem',
            },
            '&:hover:after': {
              right: -0.125,
            },
            '&:hover,&:focus,&:active,&.active': {
              color: '#333',
              backgroundColor: '#e6e6e6',
              borderColor: 'white',
            },
          },
        },
        {
          props: { variant: 'jesus' },
          style: { textTransform: 'none' },
        },
      ],
    },
  },
});

export default theme;