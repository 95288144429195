import React from "react";
import PropTypes from "prop-types";
import { styled } from '@mui/system';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../theme-light';

// @material-ui/core components
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';

import Layout from '../components/Layout';
import FullWidthImage from '../components/FullWidthImage';

const Section = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  backgroundColor: '#ffffff',
  zIndex: 1,
});

const MainContent = styled(Box)({
  margin: 'auto 64px 50px',
});

// eslint-disable-next-line
export const OACCLandingPageTemplate = ({
  image,
  title,
  subheading,
  heading,
  mainpitch,
  description,
}) => {
  const heroImage = getImage(image) || image;

  return (
    <ThemeProvider theme={theme}>
      <>
        <FullWidthImage
          img={heroImage}
          title={title}
          subheading={subheading}
          height='100%'
        />
        <Section
          className='section'
          sx={{ boxShadow: 12 }}
          mx={8}
          mt={2}
          borderRadius={2}
          elevation={3}
        >
          <MainContent px={0}>
            <Grid
              container
              spacing={2}
              sx={{ justifyContent: 'space-between' }}
              py={4}
            >
              <Grid item xs={12} sm={12} md={12} className='section'>
                <Typography variant='h2' align='center' sx={{ fontWeight: 'bold', color: '#222', textTransform: 'uppercase', letterSpacing: 2 }}>
                  {mainpitch.description}
                </Typography>
                <Typography my={5} variant='h1' align='center' sx={{ fontWeight: 'bold', color: '#222', fontSize: '4rem', lineHeight: 1.2 }}>
                  {mainpitch.title}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography variant='h3' sx={{ fontWeight: 'bold', color: '#222' }}>{heading}</Typography>
                <Typography variant='body1' sx={{ color: '#222' }}>{description}</Typography>
              </Grid>
            </Grid>
          </MainContent>
        </Section>
      </>
    </ThemeProvider>
  );
};

OACCLandingPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  subheading: PropTypes.string,
  heading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
};

const OACCLandingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout theme={theme}>
      <OACCLandingPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        subheading={frontmatter.subheading}
        heading={frontmatter.heading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
      />
    </Layout>
  );
};

OACCLandingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default OACCLandingPage;

export const pageQuery = graphql`
	query OACCLandingPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "oacc-landing" } }) {
			frontmatter {
				title
                subheading
				image {
					childImageSharp {
						gatsbyImageData(quality: 100, layout: FULL_WIDTH)
					}
				}
				heading
				mainpitch {
					title
					description
				}
				description
			}
		}
	}
`;
